import React from "react"
import HeaderV2 from "../headerv2/headerv2"
import Hero from "../../microcomponents/hero/hero"
import ClientLogos from "../../microcomponents/clientlogos/clientlogos"
import LeftRightImageText from "../../microcomponents/leftrightimagetext/leftrightimagetext"
import Button from "../../microcomponents/button/button"
import { ArrowIcon } from "../../microcomponents/icons"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import ClientResults from "../../microcomponents/clientresults/clientresults"
import Image from "../../components/image-component/image-new"

export default function ProcessV2() {
  return (
    <div>
      <HeaderV2 version="v2" />
      <Hero
        version="v2"
        className="greeneclipse"
        breadcrumb={[
          { name: "Home", url: "/" },
          {
            name: "How <span class=''>it Works</span>",
            url: "/how-it-works/",
          },
          { name: "Process", url: null },
        ]}
        subHeading={null}
        heading="See how we get <br class='hidetablet hideipad'/> you <span>38%</span> <br class='hidedesktop hidetablet hidemobile'/> lift in 90<br class='hidetablet hideipad'/> days. Guaranteed."
        description="<p>A proven playbook for getting 10 tests <br class='hidetablet hideipad'/> running, 3-5 wins, and a <br class='hidedesktop hidetablet hidemobile'/> 38% lift in <br class='hidedesktop hidetablet hideipad'/> just the <br class='hidetablet hideipad hidemobile'/> first 3 months.</p>"
        tags={["Kickoff", "Weeks 1-2", "Week 3", "Weeks 4-12", "Pilot Review"]}
        heroImage="image-interior-hero_6.webp"
        sourceFolder="spiralyze2024website"
        cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
      />
      <ClientLogos version="v2" />
      <LeftRightImageText
        mainHeading="Meet your team and see your first designs."
        subHeading="Kickoff"
        sectionDescription="<p>In a one-hour kickoff, you will meet <br class='hidetablet hideipad'/> your team. We come prepared <br class='hidedesktop hidemobile'/> with <br class='hidetablet hideipad'/> your first round of test designs ready <br class='hidetablet hideipad'/> for you to review.</p><p>We'll also align on all the technical <br class='hidetablet hideipad hidemobile'/> and analytics stuff we need to <br class='hidedesktop hidemobile'/> get the <br class='hidetablet hideipad hidemobile'/> first <br class='hidedesktop hidetablet hideipad'/> test live.</p>"
        imageFolder="how_it_works"
        wrapperClasses=""
        reverse={true}
        innerClasses="pd-112"
        id="kickoff"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              className={`shadow border-radius-12`}
              fallBackImage={`frame_1171275353.webp`}
              alt={`Kickoff`}
              imgName={`frame_1171275355.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          href={`/get-demo/`}
          version={"v2"}
          icon={<ArrowIcon />}
          type={`cta
            `}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="Testing strategy, research, and <br class='hidedesktop hidemobile'/> analytics."
        subHeading="Weeks 1-2"
        sectionDescription="<p>The testing roadmap identifies the <br class='hidetablet hideipad'/> pages that drive the most <br class='hidemobile'/> conversions <br class='hidedesktop hidetablet hideipad'/> and holds the most opportunity.</p><p>Our research team starts <br class='hidetablet hideipad hidemobile'/> heat mapping pages, analyzing <br class='hidedesktop hidemobile'/> competitors, <br class='hidedesktop hidetablet hideipad'/> conducting interviews, objections <br class='hidedesktop hidetablet hideipad'/> polling, message <br class='hidedesktop hidemobile'/> testing,<br class='hidetablet hideipad hidemobile'/> and more.</p>"
        imageFolder="how_it_works"
        wrapperClasses="gray-bg"
        sectionImage="howitworksimage2.webp"
        reverse={false}
        innerClasses="pd-112 reverse"
        id="weeks1-2"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              className={`shadow border-radius-12`}
              fallBackImage={`heatmaphomepage_v2_1.webp`}
              alt={`Kickoff`}
              imgName={`heatmaphomepage_v2_1.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          href={`/get-demo/`}
          version={"v2"}
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="First tests launch!"
        subHeading="Week 3"
        sectionDescription="<p>Your first tests will be built out, QAed,<br class='hidetablet hideipad'/> and ready to launch within <br class='hidedesktop hidemobile'/> 2-3 <br class='hidetablet hideipad hidemobile'/> weeks. <br class='hidedesktop hidetablet hideipad'/> We will send you a preview<br class='hidetablet hideipad hidemobile'/> link, and <br class='hidedesktop hidetablet hideipad'/> when you give us <br class='hidedesktop hidemobile'/> the okay,<br class='hidetablet hideipad hidemobile'/> we launch <br class='hidedesktop hidetablet hideipad'/> the test.</p><p>See live test data in your dashboard. </p>"
        imageFolder="how_it_works"
        wrapperClasses=""
        sectionImage="howitworksimage3.webp"
        reverse={true}
        innerClasses="pd-112"
        id="week3"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              className={`shadow`}
              fallBackImage={`box-image_1.webp`}
              alt={`Kickoff`}
              imgName={`box-image_1.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          href={`/get-demo/`}
          version={"v2"}
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="Full testing pipeline."
        subHeading="Weeks 4-12"
        sectionDescription="<p>We meet weekly to review test<br class='hidetablet hideipad'/> results, present new test designs, <br class='hidedesktop hidemobile'/> and <br class='hidedesktop hidetablet hideipad'/> prioritize the testing stack.</p><p>Get a full pipeline of tests. Plus, a <br class='hidetablet hideipad'/> backlog of tests to eliminate <br class='hidemobile'/> downtime and max out traffic.</p><p>As results emerge, we'll adjust the <br class='hidedesktop hidetablet hideipad'/> strategy — scaling up and <br class='hidedesktop hidemobile'/> iterating on winning tests.</p>"
        imageFolder="how_it_works"
        wrapperClasses="gray-bg"
        sectionImage="howitworksimage2.webp"
        reverse={false}
        innerClasses="pd-112 reverse"
        id="weeks4-12"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              className={`shadow`}
              fallBackImage={`box-image_4.webp`}
              alt={`Kickoff`}
              imgName={`box-image_4.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          href={`/get-demo/`}
          version={"v2"}
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="Review results and down-funnel <br class='hidemobile'/> impact."
        subHeading="Pilot Review"
        sectionDescription="<p>We debrief with a detailed review of testing results, <br class='hidedesktop hidemobile'/> learnings, and ROI.<br class='hidetablet hideipad'/>  Review strategy for future testing.</p><p>Plus, get high-level shareouts, so you <br class='hidetablet hideipad'/> can communicate project <br class='hidedesktop hidemobile'/> highlights, learnings, and impact to stakeholders.</p>"
        imageFolder="how_it_works"
        wrapperClasses=""
        sectionImage="howitworksimage3.webp"
        reverse={true}
        innerClasses="pd-112"
        id="pilotreview"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              className={`shadow`}
              fallBackImage={`box-image_8.webp`}
              alt={`Pilot Review`}
              imgName={`box-image_8.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          href={`/get-demo/`}
          version={"v2"}
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>
      <TopPredictions version="v2" />
      <ClientResults version="v2" />
    </div>
  )
}
